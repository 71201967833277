var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "columns is-multiline m0 global_box" },
    _vm._l(_vm.kpi_boxes, function (box, index) {
      return _c("div", { key: index, staticClass: "column kpibox" }, [
        _c("div", { staticClass: "kpibox-shadow one-hundred-height" }, [
          _c(
            "div",
            { staticClass: "kpibox-header ikea-yellow columns content_align" },
            [
              _c("HomeGlobalIcons", { attrs: { icon: box.name } }),
              box.sub_categories.length > 1
                ? _c(
                    "div",
                    { staticClass: "column kpibox-text global_header" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(box.name) +
                          " " +
                          _vm._s(
                            box.sub_categories
                              .map((subCategory) => subCategory.name)
                              .join(" / ")
                          ) +
                          " "
                      ),
                      _vm.getKpiData(box).formatted_text
                        ? _c("span", { staticClass: "formatted-text" }, [
                            _vm._v(
                              " (" +
                                _vm._s(_vm.getKpiData(box).formatted_text) +
                                ") "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  )
                : _c(
                    "div",
                    { staticClass: "column kpibox-text global_header" },
                    [
                      _vm._v(" " + _vm._s(box.name) + " "),
                      _vm.getKpiData(box).formatted_text
                        ? _c("span", { staticClass: "formatted-text" }, [
                            _vm._v(
                              " (" +
                                _vm._s(_vm.getKpiData(box).formatted_text) +
                                ") "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "kpi-table" },
            [
              _c("GlobalBox", {
                attrs: { data: _vm.getKpiData(box), name: box.name },
              }),
            ],
            1
          ),
        ]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }